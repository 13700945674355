<template>
	<div class="index-view">
		<div class="header">
			<div class="bg">
				<img src="../../assets/images/index_title_bg.png" alt />
			</div>
			<!-- <div class="nav-box">
				<p
					class="header-item"
					v-for="(item,index) in typeOneList"
					:key="index"
					:class="currentIndex==index?'active':''"
					v-show="item.publish"
					@click="clickItem(index)"
				>{{item.typeName}}</p>
			</div>-->
			<div class="nav-box">
				<div class="swiper-buttom-prev" @click="prevButtonClick">
					<img class="swiper-turn" src="../../assets/images/type_arrow_left.jpg" />
				</div>
				<div class="swiper-container">
					<div class="swiper-wrapper">
						<div
							class="swiper-slide"
							:class="currentIndex === index ? 'active header-item':'header-item' "
							v-for="(item,index) of typeOneList"
							:key="item.id"
						>
							<p @click="clickItem(index)">{{item.typeName}}</p>
						</div>
					</div>
				</div>
				<div class="swiper-buttom-next" @click="nextButtonClick">
					<img class="swiper-turn" src="../../assets/images/type_arrow_right.jpg" />
				</div>
			</div>
		</div>
		<div class="second-nav" id="second-nav-height" :style="width=''">
			<p
				class="nav-iem"
				v-for="(item,index) in list"
				:key="index"
				:class="currentTwoIndex == index?'select':'no-select'"
				v-show="item.publish"
				@click="clickTwoItem(index)"
			>{{item.typeName}}</p>
		</div>
		<div class="second-more" v-if="!showMore" @click="showMoreList">
			<p>显示更多</p>
			<el-icon>
				<ArrowDown />
			</el-icon>
		</div>
		<div class="second-more" v-else @click="putMoreList">
			<p>收起更多</p>
			<el-icon>
				<ArrowUp />
			</el-icon>
		</div>
		<div class="list">
			<div class="list-title">
				<p style="font-size:27px;color:#000">{{titleName}}</p>
				<div class="title-right">
					<p style="font-size:18px;color:#000">
						共
						<span style="color:#71B7FF">{{typeThreeList.length}}</span>个事项
					</p>
					<div class="title-search">
						<el-input class="search-input" v-model="input" placeholder="输入事项名称关键词搜索"></el-input>
						<div class="search-txt" icon="el-icon-search" @click="searchData">
							<img class="search-img" src="../../assets/images/index_search.jpg" />
						</div>
					</div>
				</div>
			</div>
			<div
				class="list-item"
				v-for="(item,index) in typeThreeList"
				:key="index"
				v-show="item.publish"
				@click="openWorkDetail(item)"
			>
				<div class="item-left">
					<img class="item-img" src="../../assets/images/index_ling.png" alt />
					<p class="item-txt">{{item.typeName}}</p>
				</div>
				<div class="item-right">进入</div>
			</div>
		</div>
		<footer-view></footer-view>
	</div>
</template>

<script>
import api from "../../api/index";
import { ArrowDown, ArrowUp } from "@element-plus/icons-vue";
import FooterView from '@/components/FooterVew.vue'

import Swiper from 'swiper'
//require styles
import 'swiper/css/swiper.css'
export default {
	components: {
		ArrowDown,
		ArrowUp,
		FooterView
	},
	data () {
		return {
			input: '',
			data: [],
			typeOneList: [],
			typeTwoList: [],
			list: [],
			parentId: 0,
			currentIndex: 0,
			titleName: '',
			twoParentId: 0,
			typeThreeList: [],
			currentTwoIndex: 0,
			search: '',
			mySwiper: null,
			showMore: false,
			secondHeight: 0
		}
	},

	mounted () {
		this.getTypeList()
		this.mySwiper = new Swiper(".swiper-container", {
			loop: true,
			observer: true,
			observeParents: true,
		})

	},
	methods: {
		getSecondNavHeight () {
			let div = document.getElementById('second-nav-height');
			//  if(this.showMore){
			//   document.getElementById('second-nav-height').style.height = 160+'px'
			//  }else{
			//    document.getElementById('second-nav-height').style.height = 
			//  }
			this.secondHeight = div.offsetHeight
		},
		prevButtonClick () {
			this.mySwiper.slidePrev();
		},
		nextButtonClick () {
			this.mySwiper.slideNext();
		},
		clickItem (index) {
			this.currentIndex = index
			this.parentId = this.typeOneList[index].id
			this.typeTwoList = this.data.filter(item => {
				return item.parentId == this.parentId
			})
			this.typeTwoList = this.typeTwoList.sort((a, b) => {
				return a.sort - b.sort
			})
			this.getList()
			this.getTypeThreeList(this.currentTwoIndex)
		},
		clickTwoItem (index) {
			this.currentTwoIndex = index
			this.getTypeThreeList(index)
		},
		searchData () {
			this.search = this.input
			this.getTypeThreeList(this.currentIndex)
		},
		showMoreList () {
			this.showMore = true
			this.list = this.typeTwoList
		},
		putMoreList () {
			this.showMore = false
			this.getList()
		},
		//获取三级树
		getTypeList: async function () {
			const res = await api.getTypeList();
			if (res.code == 200) {
				this.data = res.data
				if (Array.isArray(this.data) && this.data.length > 0) {
					this.typeOneList = this.data.filter(item => {
						return item.parentId == 0
					})
					this.typeOneList = this.typeOneList.sort((a, b) => {
						return a.sort - b.sort
					})

					if (Array.isArray(this.typeOneList) && this.typeOneList.length > 0) {
						this.parentId = this.typeOneList[0].id
						this.typeTwoList = this.data.filter(item => {
							return item.parentId == this.parentId
						})
						this.typeTwoList = this.typeTwoList.sort((a, b) => {
							return a.sort - b.sort
						})
						this.getList()

						this.getTypeThreeList(this.currentIndex)
					}

				}
			}
		},
		getList () {
			if (Array.isArray(this.typeTwoList) && this.typeTwoList.length >= 5) {
				this.list = this.typeTwoList.slice(0, 5)
			} else {
				this.list = this.typeTwoList
			}

		},
		getTypeThreeList (index) {
			if (Array.isArray(this.typeTwoList) && this.typeTwoList.length > 0) {
				this.twoParentId = this.typeTwoList[index].id
				this.titleName = this.typeTwoList[index].typeName
				let threeData = this.data.filter(item => {
					return item.parentId == this.twoParentId
				})
				if (this.search) {
					this.typeThreeList = threeData.filter(item => {
						const {
							typeName = ""
						} = item;
						return typeName.indexOf(this.search) > -1;
					})
				} else {
					this.typeThreeList = threeData
				}

				this.typeThreeList = this.typeThreeList.sort((a, b) => {
					return a.sort - b.sort
				})

			} else {
				this.twoParentId = 0
				this.titleName = ''
				this.typeThreeList = []
			}
		},
		openWorkDetail (item) {
			this.$router.push({
				name: 'detail',
				query: {
					id: item.id,
					typeId: this.parentId
				}
			})
		}
	}
};
</script>

<style scoped lang='scss'>
.index-view {
	height: 100%;
	box-sizing: border-box;
}
.header {
	display: flex;
	position: relative;
	width: 100%;
	.bg {
		display: inline-flex;
		img {
			display: block;
			width: 100%;
			z-index: -1;
		}
	}
	.nav-box {
		display: flex;
		position: absolute;
		right: 0;
		bottom: 0;
		left: 0;
		height: 70px;
		margin-left: 277px;
		margin-right: 292px;
		box-sizing: border-box;
		.swiper-container {
			width: calc(100% - 135px);
		}
		.swiper-slide {
			width: 160px !important;
		}
		.swiper-turn {
			width: 22px;
			height: 39px;
			margin-top: 10px;
		}
	}
	.header-item {
		height: 70px;
		margin: 0 15px;
		width: 160px;
		text-align: center;
		box-sizing: border-box;
		background-color: rgb(0, 0, 0, 0.46);
		line-height: 70px;
		font-size: 25px;
		color: #fff;
	}
	.header-item.active {
		background-color: #fff;
		color: #000;
	}
}
.second-nav {
	padding-left: 255px;
	padding-right: 270px;
	margin-top: 25px;

	.nav-iem {
		display: inline-block;
		height: 60px;
		line-height: 60px;
		background-color: #f1f2f3;
		color: #000000;
		font-size: 18px;
		padding: 0 25px;
		margin: 20px 15px 0 15px;
	}
	.select {
		background-color: #71b7ff;
		color: #fff;
	}
}
.second-more {
	height: 40px;
	line-height: 40px;
	background-color: #f1f2f3;
	color: #71b7ff;
	font-size: 16px;
	text-align: center;
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 85px 270px 0 270px;
	justify-content: center;
	cursor: pointer;
}
.list {
	padding: 41px 270px 0 270px;
	.list-title {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding-bottom: 15px;
		border-bottom: #f1f2f3 2px solid;
		.title-right {
			display: flex;
			flex-direction: row;
			align-items: center;
			.title-search {
				display: flex;
				flex-direction: row;
				width: 309px;
				height: 40px;
				.search-input {
					flex: 1;
					height: 100%;
					margin-left: 28px;
				}
				.search-txt {
					height: 100%;
					width: 45px;
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: center;
					color: #fff;
					background-color: #71b7ff;
					.search-img {
						width: 20px;
						height: 20px;
						display: inline-block;
					}
				}
			}
		}
	}
	.list-item {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 36px 0;
		border-bottom: #f1f2f3 2px solid;
		.item-left {
			flex: 1;
			padding-right: 20px;
			display: flex;
			flex-direction: row;
			align-items: center;
			.item-img {
				width: 15px;
				height: 15px;
			}
			.item-txt {
				margin-left: 34px;
				font-size: 20px;
				color: #000;
			}
		}
		.item-right {
			flex: 0 0 77px;
			color: #71b7ff;
			border: #71b7ff 1px solid;
			border-radius: 7px;
			height: 38px;
			line-height: 38px;
			font-size: 18px;
			text-align: center;
		}
	}
}
</style>