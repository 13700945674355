<template>
	<div class="footer-view">
		<div class="footer-left">
			<p class="left-title">河北省石家庄市桥西区政务服务中心</p>
			<div class="left-content">
				<div class="content-item">
					<div class="item-tips">
						<img class="tips-img" src="../assets/images/footer_phone.jpg" />
						<p class="tips-txt">咨询热线</p>
					</div>
					<p class="item-name">0311-68005255</p>
				</div>
				<div class="content-item" style="margin-left:170px">
					<div class="item-tips">
						<img class="tips-img" src="../assets/images/footer_address.jpg" />
						<p class="tips-txt">公司地址</p>
					</div>
					<p class="item-name">桥西区新石中路377号物联网大厦B座1层</p>
				</div>
			</div>
		</div>
		<div class="footer-right">
			<p class="right-title">微信公众号</p>
			<img class="right-img" src="../assets/images/qr_code.jpg" />
			<p class="right-txt" style="margin-top:5px">及时了解最新资讯</p>
		</div>
	</div>
</template>
<script>

export default {}
</script>
<style scoped lang='scss'>
.footer-view {
	width: 100%;
	box-sizing: border-box;
	background-color: #131c52;
	padding: 10px 270px;
  margin-top: 100px;
	display: flex;
	flex-direction: row;
	align-items: center;
	.footer-left {
		flex: 1;
		.left-title {
			color: #fff;
			font-size: 25px;
		}
		.left-content {
			display: flex;
			flex-direction: row;
			margin-top: 50px;
			.item-tips {
				display: flex;
				flex-direction: row;
        align-items: center;
				.tips-img {
					width: 18px;
					height: 16px;
					display: inline-block;
				}
				.tips-txt {
					margin-left: 14px;
					color: #fff;
          font-weight: 300;
					font-size: 16px;
				}
			}
			.item-name {
				margin-top: 15px;
				color: #fff;
				font-size: 20px;
			}
		}
	}
	.footer-right {
		flex: 0 0 120px;
    margin-left: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
		.right-title {
			color: #fff;
			font-size: 20px;
		}
		.right-img {
			width: 120px;
			height: 120px;
      margin-top: 5px;
			display: inline-block;
		}
    .right-txt{
      color: #fff;
      font-size: 14px;
      font-weight: 300;
    }
	}
}
</style>
