<template>
	<div class="home">
		<router-view />
	</div>
</template>

<script>

export default {
	name: "HomeView",
};
</script>
<style scoped>
.home{
  height: 100%;
  width: 100%;
}
</style>
