<template>
	<div class="material-list">
		<div class="list" v-if="materialList.length>0">
			<div class="list-item" v-for="(item,index) in materialList" :key="index">
				<div class="item-number">{{index+1}}</div>
				<div class="item-content">
					<div class="item-txt">
						<p>{{item.fileName}}</p>
					</div>
					<div class="item-buts">
						<p class="buts download" @click="downDoc(item.fileUrl)">下载表格</p>
						<el-link class="buts check" :href="item.fileUrl">查看表格</el-link>
					</div>
				</div>
			</div>
		</div>
		<no-data v-else :name="noDataTxt"></no-data>
	</div>
</template>
<script>
import api from "../api/index";
import NoData from "./NoData.vue"
export default {
	props: {
		id: {
			type: String,
			default: ''
		},
		category: {
			type: Number,
			default: 1
		}
	},
	components: {
		NoData
	},
	data () {
		return {
			materialList: [],
			noDataTxt: '当前暂无材料清单'
		}
	},
	mounted () {
		this.getMaterrialLst()
	},
	watch: {
		category (newVal) {
			this.getMaterrialLst()
			if (newVal == 1) {
				this.noDataTxt = '当前暂无材料清单'
			} else {
				this.noDataTxt = "当前暂无办理规范"
			}
		}
	},
	methods: {
		//材料清单
		getMaterrialLst: async function () {
			const res = await api.getMaterrialLst(this.id, this.category);
			if (res.code == 200) {
				this.materialList = res.rows
			}
		},
		downDoc (fileUrl) {
			const a_link = document.createElement("a"); // 生成一个a链接
			fetch(fileUrl) // 括号里是文件链接
				.then((res) => res.blob())
				.then((blob) => {
					// 将链接地址字符内容转变成blob地址
					a_link.href = URL.createObjectURL(blob);
					console.log(a_link.href);
					a_link.download = name; //下载的文件的名字
					document.body.appendChild(a_link);
					a_link.click();
				});
		}
	}
}
</script>
<style scoped lang='scss'>
.material-list {
	height: 100%;
	overflow: auto;
}
.list {
	width: 100%;
	border-left: #dddddd 1px solid;
	border-right: #dddddd 1px solid;
	border-top: #dddddd 1px solid;
	box-sizing: border-box;
	.list-item {
		display: flex;
		flex-direction: row;
		align-items: center;
		height: 100px;
		width: 100%;
		box-sizing: border-box;

		.item-number {
			height: 100px;
			width: 44px;
			background-color: #e0f1ff;
			text-align: center;
			line-height: 100px;
			border-right: #dddddd 1px solid;
			border-bottom: #dddddd 1px solid;
		}
		.item-content {
			display: flex;
			padding-left: 40px;
			flex-direction: row;
			width: 100%;
			height: 100px;
			line-height: 100px;
			align-items: center;
			border-bottom: #dddddd 1px solid;

			.item-txt {
				flex: 1;
				font-size: 16px;
			}

			.item-buts {
				display: flex;
				flex-direction: row;
				flex: 0 0 202px;
				padding-right: 15px;
				.buts {
					width: 86px;
					height: 29px;
					line-height: 29px;
					text-align: center;
					font-size: 14px;
					border-radius: 7px;
				}
				.download {
					border: #71b7ff 1px solid;
					color: #71b7ff;
				}
				.check {
					border: #ee9610 1px solid;
					color: #ee9610;
					margin-left: 15px;
				}
			}
		}
	}
}
</style>
