<template>
	<div>
		<div v-if="mainPoint" class="zhengwen" v-html="mainPoint"></div>
		<no-data v-else :name="noDataTxt"></no-data>
	</div>
</template>
<script>
import NoData from "./NoData.vue"
export default {
	props: {
		mainPoint: {
			type: String,
			default: ''
		}
	},
	components: {
		NoData
	},
	data () {
		return {
			noDataTxt: '当前暂无审查要点'
		}
	},
	watch: {
		mainPoint (newVal) {
			if (newVal) {
				this.getNameDes()
			}
		}
	},
	methods: {
		getNameDes () {
			if (this.mainPoint.length > 300) {
				this.name = this.mainPoint.substring(0, 300)
				this.des = this.mainPoint.substring(300, this.mainPoint.length)
			} else {
				this.name = this.mainPoint
			}

		}
	}
}
</script>
<style scoped lang='scss'>
.zhengwen {
	padding: 40px 20px 40px 30px;
}

</style>
<style >
.zhengwen p{
  font-size: 16px;
}
</style>
