import request from "@/axios";
let url = "https://handy.etuic.com/prod-api"

export default {
  //获取三级树
  getTypeList () {
    // return request.get(process.env.VUE_APP_BASE_API + "/open/art/article/list", params);
    return request.get(url + "/service/type/list");
  },
  //详情
  getDetail (id) {
    return request.get(url + "/service/type/" + id);
  },
  //材料清单
  getMaterrialLst (id, category) {
    return request.get(url + "/service/stuff/list?pageNum=1&pageSize=10&endTypeId=" + id + '&category=' + category);
  },
  //全部问题
  getQuestion (id) {
    return request.get(url + "/service/question/list?pageNum=1&pageSize=1000&publish=true&typeId=" + id);
  },
  //我的问题
  getMyQuestion (id) {
    return request.get(url + "/service/question/list?pageNum=1&pageSize=1000&typeId=" + id);
  },
  //获取字典
  getdict () {
    return request.get(url + "/service/dict/list?pageNum=1&pageSize=100");
  }
}
