<template>
	<div class="detail-view">
		<div class="detail-body">
			<div class="header">
				<div class="header-top">
					<div class="top-item top-left">
						<p class="line"></p>
						<p class="item-top">{{detailBean.typeName}}</p>
					</div>

					<div class="top-item top-right">
						<p class="line"></p>
						<p class="item-top">业务部门</p>
					</div>
				</div>
				<div class="header-bottom">
					<p
						class="bottom-left item-bottom"
						style="margin-left:25px"
					>{{detailBean.rootTypeName}}</p>
					<p
						class="bottom-right item-bottom"
						style="margin-left:110px"
					>{{detailBean.deptName}}</p>
				</div>
			</div>
			<div class="body">
				<div class="body-nav">
					<div
						class="nav-item"
						:class="tabCurrentIndex == 0?'active':''"
						@click="clickTabItem(0)"
					>材料清单</div>
					<div
						class="nav-item"
						:class="tabCurrentIndex == 1?'active':''"
						@click="clickTabItem(1)"
					>办理规范</div>
					<div
						class="nav-item"
						:class="tabCurrentIndex == 2?'active':''"
						@click="clickTabItem(2)"
					>审查要点</div>
					<!-- <div
						class="nav-item"
						:class="tabCurrentIndex == 2?'active':''"
						@click="clickTabItem(2)"
					>常见问题</div>-->
					<div
						class="nav-item"
						:class="tabCurrentIndex == 3?'active':''"
						@click="clickTabItem3(3,)"
					>{{detailBean.url?"在线办理":'窗口办理'}}</div>
					<div
						class="nav-item"
						:class="tabCurrentIndex == 4?'active':''"
						@click="clickTabItem(4)"
					>立即咨询</div>
				</div>
				<div class="box">
					<div class="trigngle-box" :class="tabCurrentIndex == 0?'':'visible'">
						<div class="triangle"></div>
					</div>
					<div class="trigngle-box" :class="tabCurrentIndex == 1?'':'visible'">
						<div class="triangle"></div>
					</div>
					<div class="trigngle-box" :class="tabCurrentIndex == 2?'':'visible'">
						<div class="triangle"></div>
					</div>
					<div class="trigngle-box" :class="tabCurrentIndex == 3?'':'visible'">
						<div class="triangle"></div>
					</div>
					<div class="trigngle-box" :class="tabCurrentIndex == 4?'':'visible'">
						<div class="triangle"></div>
					</div>
				</div>
				<div class="compon">
					<material-list
						v-show="tabCurrentIndex == 0||tabCurrentIndex == 1"
						:id="id"
						:category="tabCurrentIndex == 0?1:2"
					></material-list>
					<review-points
						v-show="tabCurrentIndex == 2"
						:mainPoint="detailBean.mainPoint"
					></review-points>
					<!-- <common-problem v-show="tabCurrentIndex == 2" :id='id'></common-problem> -->
					<!-- <all-question v-show="tabCurrentIndex == 2" :id="id"></all-question> -->

					<no-data
						v-show="tabCurrentIndex == 3&&!detailBean.url"
						:name="noDataTxt"
						:urlImg="url"
					></no-data>
					<consult-index v-show="tabCurrentIndex == 4"></consult-index>
				</div>
			</div>
		</div>
		<footer-view></footer-view>
	</div>
</template>
<script>
import MaterialList from '@/components/MaterialList.vue'
import ReviewPoints from '@/components/ReviewPoints.vue'
// import CommonProblem from '@/components/CommonProblem.vue'
// import AllQuestion from "@/components/AllQuestion.vue";
import ConsultIndex from "@/components/ConsultIndex.vue"
import NoData from "@/components/NoData.vue"
import api from "../../api/index";
import FooterView from '@/components/FooterVew.vue'
export default {
	components: {
		MaterialList,
		ReviewPoints,
		// CommonProblem
		// AllQuestion,
		ConsultIndex,
		NoData,
		FooterView
	},
	data () {
		return {
			id: this.$route.query.id,
			typeId: this.$route.query.typeId,
			tabCurrentIndex: 0,
			detailBean: {},
			serviceUrl: '',
			noDataTxt: '当前业务线上办理暂未开放，请去线下窗口办理',
			url: require('../../assets/images/wiindows_img.png')
		}
	},
	mounted () {
		this.getDetail()
	},
	methods: {
		clickTabItem (index) {
			this.tabCurrentIndex = index
		},
		clickTabItem3 (index) {
			this.tabCurrentIndex = index
			if (this.detailBean.url) {
				window.open(this.detailBean.url)
			}
		},
		//获取详情
		getDetail: async function () {
			const res = await api.getDetail(this.id);
			if (res.code == 200) {
				this.detailBean = res.data
			}
		},

	}
}
</script>
<style scoped lang='scss'>
.detail-view {
	// width: 100%;
	// height: 100%;
	// position: absolute;
	// overscroll-behavior: contain;
	// overflow: auto;
	padding-top: 36px;
	box-sizing: border-box;

	.detail-body {
		margin: 0 270px 0 270px;
		width: calc(100% - 540px);
		// height: calc(100% - 36px);
		box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
		display: flex;
		flex-direction: column;
		.header {
			padding: 39px 24px 25px 18px;
			flex: 0 0 108px;
			box-sizing: border-box;

			.header-top {
				display: flex;
				flex-direction: row;
				padding-left: 73px;
				padding-right: 100px;
				.top-item {
					display: flex;
					flex-direction: row;
					.line {
						background-color: #4196ec;
						width: 3px;
						height: 45px;
						margin-right: 20px;
					}
					.item-top {
						font-size: 25px;
						color: #000;
					}
				}
				.top-left {
					flex: 3;
					padding-right: 30px;
				}
				.top-right {
					flex: 1;
				}
			}
			.header-bottom {
				display: flex;
				flex-direction: row;
				padding-left: 73px;
				padding-right: 100px;
				.item-bottom {
					font-size: 18px;
					padding-top: 10px;

					color: #000;
				}
				.bottom-left {
					flex: 3;
				}
				.bottom-right {
					flex: 1;
				}
			}
		}
		.body {
			// flex: 1;
			border: #dddddd 1px solid;
			padding: 24px 24px 0 24px;
			height: 543px;
			display: flex;
			flex-direction: column;
			box-sizing: border-box;
			.body-nav {
				background-color: #f1f2f4;
				height: 56px;
				display: flex;
				flex-direction: row;
				.nav-item {
					width: 170px;
					height: 56px;
					text-align: center;
					line-height: 56px;
					font-size: 16px;
				}
				.active {
					color: #fff;
					background-color: #1292ff;
				}
			}
			.box {
				display: flex;
				flex-direction: row;
				.visible {
					visibility: hidden;
				}
				.trigngle-box {
					width: 170px;
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: center;
					.triangle {
						/*宽高为0*/
						width: 0;
						height: 0;
						border-top: 14px solid #1292ff;
						border-bottom: 14px solid white;
						border-left: 14px solid white;
						border-right: 14px solid white;
					}
				}
			}
			.compon {
				flex: 1;
				overflow-y: auto;
				padding-bottom: 10px;
			}
		}
	}
}
</style>
