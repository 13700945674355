<template>
	<div class="no-data">
		<img class="no-img" :src="urlImg" />
		<p class="no-name">{{name}}</p>
	</div>
</template>
<script>

export default {
	props: {
		name: {
			type: String,
			default: '当前暂无数据'
		},
		urlImg: {
			type: String,
			default: require('../assets/images/no_data_img.png')
		}
	}

}
</script>
<style scoped lang='scss'>
.no-data {
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
	justify-content: center;
	.no-img {
		width: 350px;
		height: 260px;
    margin-top: 10px;
	}
	.no-name {
		margin-top: 50px;
    color: #B4B6BA;
    font-size: 20px;
	}
}
</style>
