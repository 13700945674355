<template>
	<div class="consult-index">
		<div class="tips">
      <p class="tips-txt">如有问题需要咨询客服，请打开微信扫一扫下方二维码</p>
    </div>
		<div class="qr-code"  ref="qrCodeUrl"></div>
	</div>
</template>
<script>
import api from "../api/index";
import QRCode from 'qrcodejs2'
export default {
	data () {
		return {
			serviceUrl: ''
		}
	},
	mounted () {
		this.init()
	},

	methods: {
		async init () {
			await this.getDict()
			this.creatQrCode()
		},
		//获取字典
		getDict: async function () {
			const res = await api.getdict();
			if (res.code == 200) {
				let data = res.rows
				if (Array.isArray(data) && data.length > 0) {
					let bn = data.find(item => {
						return item.propName === 'serviceUrl'
					})
					this.serviceUrl = bn.propValue
				}
			}
		},
		creatQrCode () {
			var qrcode = new QRCode(this.$refs.qrCodeUrl, {
				text: this.serviceUrl, // 需要转换为二维码的内容
				width: 200,
				height: 200,
				colorDark: '#000000',
				colorLight: '#ffffff',
				correctLevel: QRCode.CorrectLevel.H
			})
		},
	}
}
</script>
<style scoped lang='scss'>
.consult-index{
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .tips{
    background-color: #E2F1FF;
    border-radius: 4px;
    width: 777px;
    margin-top: 30px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    .tips-txt{
      color: #1292FF;
      font-size: 16px;
    }
  }
  .qr-code{
    margin-top: 56px;
    border: #D1D1D1 1px solid;
    border-radius: 6px;
    padding: 15px;

  }
}
</style>
