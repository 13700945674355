import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import IndexView from '../views/nav/IndexView.vue'
import DetailView from '../views/details/DetailView.vue'

const routes = [
  {
    path: "/",
    name: "home",
    redirect:'/index/index',
    component: HomeView,
    children: [
      {
        path: "/index/index",
        name: "home",
        component: IndexView,
      },
      {
        path: "/detail/detail",
        name: "detail",
        component: DetailView,
      }
    ]
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
