import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import ElementUI from "element-plus";
import "element-plus/dist/index.css"



const app = createApp(App)
app.use(ElementUI)
app.use(store)
app.use(router)
app.mount("#app");
// new Vue({
//   router,
//   store,
//   render: h => h(App),
// }).$mount('#app')
