<template>
	<div id="app">
		<div class="app-title">
			<div class="app-title-name">
				<img class="title-name-img" src="./assets/images/index_title_icon.png" alt />
				<p class="title-name-txt">河北省石家庄市桥西区政务服务中心</p>
			</div>
			<router-link to="/">
				<div class="app-nav">首页</div>
			</router-link>
		</div>
    <div class="app-content">
		<router-view />
    </div>
	</div>
</template>
<script>

export default {
	openIndex () {

	}
}
</script>

<style lang="scss">
html {
	height: 100%;
}
body,
p,
ul,
ol,
li {
	margin: 0;
	padding: 0;
}
ul,
ol,
li {
	list-style: none;
}
body {
	position: relative;
	width: 100%;
	height: 100%;
	font-size: 14px;
	color: #000;
	font-family: "Micdoft YaHei";
}
#app {
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
}
.router-link-active {
  text-decoration: none;
  color: #fff;
}
a {
  text-decoration: none;
  color: #fff;
}
.app-title {
  width: 100%;
	padding-left: 270px;
	padding-top: 27px;
  background-color: #fff;
  position: fixed;
  z-index: 999;
  box-shadow: 4px 4px 4px rgb(0, 0, 0,0.16);
	.app-title-name {
		display: flex;
		flex-direction: row;
		align-items: center;
		.title-name-img {
			width: 73px;
			height: 73px;
		}
		.title-name-txt {
			font-size: 36px;
			color: #000;
			margin-left: 17px;
			font-weight: 700;
		}
	}
}
.app-content{
  margin-top: 160px;
}
.app-nav {
	color: #289bff;
	width: 73px;
	margin-top: 20px;
	text-align: center;
	padding-bottom: 10px;
	font-size: 20px;
	border-bottom: #289bff 2px solid;
}
</style>
